.header, .headerServ, .headerGal
    position: absolute
    text-align: center
    transition: 0.4s
    z-index: 1
    box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.25)
.header
    background-color: #ffffff
    height: 108px
    width: 50%


.headerServ
    background-color: #ffffff
    height: 100vh
    width: 50%

.headerGal
    background-color: #ffffff
    height: 100vh
    width: 100%

.logo
    width: 22rem
    margin-top: 0

    cursor: pointer
    transition: 1s

.logo:hover
    transform: scale(1.2)
    

@media screen and ( max-width: 768px )
    .header, .headerServ, .headerGal
        min-width: 100vw
        height: 10%
        box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.25)

    .header
        background-color: #ffffff
        min-height: 80px
        height: 10%
        width: 50%

    .headerServ
        background-color: #ffffff
        height: 100vh
        width: 50%

    .headerGal
        background-color: #ffffff
        height: 100vh
        width: 100%
        overflow-y: unset

    .logo
        width: 17rem
        cursor: pointer



@media screen and ( max-width: 568px )
    .logo
        width: 12rem

    .header 
        min-height: 60px
        height: 10%